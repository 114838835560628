<template>
  <div style="background: #f5f5f5; min-height: 100vh;height: auto;">
    <div class="header">
      <van-nav-bar
        :title="$t('C2CDepositRecord')"
        left-arrow
        @click-left="$router.go(-1)"
      />
    </div>
    <div style="padding-top: 55px;">
      <van-list
        v-model="loading"
        :finished="finished"
        :finished-text="$t('common.nomore')"
        :loading-text="$t('jia-zai-zhong')"
        @load="onLoad"
      >
        <div v-for="item in list" :key="item.id" @click="onItemClick(item)">
          <van-swipe-cell>
            <div class="cardV">
              <div class="itemV1">
                <span class="itemTv1">{{ item.price }}</span>
                <span v-if="item.status == 0" class="itemTv5">{{
                  $t("Matching")
                }}</span>
                <span v-if="item.status == 1" class="itemTv5">{{
                  $t("ToBePaid")
                }}</span>
                <span v-if="item.status == 2" class="itemTv5">{{
                  $t("ToBeReviewed")
                }}</span>
                <span v-if="item.status == 3" class="itemTv5">{{
                  $t("RechargeSuccessful")
                }}</span>
                <span v-if="item.status == 4" class="itemTv2">{{
                  $t("RechargeFailed")
                }}</span>
              </div>
              <div class="itemV2">
                <span class="itemTv3">{{ item.create_time | dateformat }}</span>
                <span class="itemTv4">{{ item.order_sn }}</span>
              </div>
            </div>
          </van-swipe-cell>
        </div>
      </van-list>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      page: 0,
    };
  },
  created() {},
  methods: {
    async onLoad() {
      this.page++;
      const { data } = await this.$http.get(
        "/home/user/rechargeCList?page=" + this.page
      );
      if (data) {
        this.loading = false;
        if (data.code === 200) {
          if (data.data.list.length == 0) {
            this.finished = true;
          }
          if (this.page == 1) {
            this.list = data.data.list;
          } else {
            this.list.push(...data.data.list);
          }
        }
      }
    },
    onItemClick(item) {
      if (item.status == 0) {//匹配中
        this.$toast(this.$t('WaitingToBeAssigned'));
      } else if (item.status == 1) {//待支付
        this.$router.push({ path: "/assets/WithdrawPay/" + item.id });
      } else if (item.status == 2) {//待审核
        this.$router.push({ path: "/assets/WithdrawDetails/" + item.id });
      } else if (item.status == 3 || item.status == 4) {
        this.$router.push({ path: "/assets/WithdrawDetails/" + item.id });
      }
    },
  },
};
</script>
<style lang="less" scoped>
.cardV {
  height: 4rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom: 0.1px solid #f2f3f5;
}
.itemV1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.itemV2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
}
.itemTv1 {
  color: #333;
  font-size: 15px;
}
.itemTv2 {
  font-weight: 500;
  color: #e74b71;
  font-size: 15px;
}
.itemTv3 {
  color: #969799;
  font-size: 10px;
}
.itemTv4 {
  color: #969799;
  font-size: 10px;
}
.itemTv5 {
  font-weight: 500;
  color: #333;
  font-size: 15px;
}

.addTv {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  height: 3.18rem;
  font-family: "Alibaba PuHuiTi";
  font-style: normal;
  font-weight: 700;
  position: fixed;
  right: 1rem;
  top: 60%;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
